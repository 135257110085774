<template>
  <section class="main-section">
    <div class="shops-cities">
      <div v-for="(c, k) in cities" :key="k" v-on:click="filterCity(c, k)" :class="{ active: activeCity === k }">
        {{ c }}
      </div>
    </div>
    <div class="shops" v-if="shops">
      <div class="shops-card" v-for="(s, k) in shops" :key="k">
        <a :href="'/shop/' + k">
          <div class="shops-img">
            <img :src="ossPath + k + '/main.png'" width="100%" />
            <div class="shops-link"></div>
          </div>
        </a>
        <div class="shops-title">{{ s.title }}</div>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  data: () => ({
    shops: null,
    cities: ['全国', '上海', '北京', '香港', '南昌', '长沙', '郑州', '重庆', '苏州', '衡阳'],
    activeCity: 0,
    ossPath: 'https://ctk-website.oss-cn-shanghai.aliyuncs.com/maier/m/space/shop/'
  }),
  created () {
    this.$store.commit('updateActiveNav', 2)
    this.shops = this.$shops
  },
  computed: {
    info () {
      return this.$store.getters.companyInfo
    }
  },
  methods: {
    filterCity (city, index) {
      this.activeCity = index
      if (city !== '全国') {
        let shops = this.$shops
        let filteredShops = {}
        for (let k in shops) {
          if (shops[k].city === city) {
            filteredShops[k] = shops[k]
          }
        }
        this.shops = filteredShops
      } else {
        this.shops = this.$shops
      }
    }
  }
}
</script>
<style lang="css">
  @import '~@/assets/css/shop.css';
</style>
